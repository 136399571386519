import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { useData } from "./useData";
import { useNavigate } from "react-router-dom";
export const baseUrl = "https://xw8v-tcfi-85ay.n7.xano.io/api:easylambda";
const context = createContext({
  isAuthenticated: false,
  token: "",
  loginWithPassword: async (email: string, password: string) => {},
  loginWithCircle: async () => {},
  signupWithPassword: async (email: string, password: string) => {},
  logout: () => {},
  refresh: async () => {},
});
const { Provider } = context;
const Authenticator: FC<{
  children: ReactElement;
  fallback: ReactElement;
}> = ({ children, fallback }) => {
  const [token, setToken] = useState<string>("");
  useEffect(() => {
    //lets get my xano token from the localStorage
    const token = localStorage.getItem("xano-token");

    if (token) {
      setIsAuthenticated(true);
      setToken(token);
      (async () => {
        const response = await fetch(`${baseUrl}/auth/refresh`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status !== 200) {
          localStorage.removeItem("xano-token");
          setIsAuthenticated(false);
        } else {
          const { authToken } = await response.json();
          setIsAuthenticated(true);
          setToken(authToken);
        }
      })();
    }
  }, []);
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setToken("");
    localStorage.removeItem("xano-token");
  }, []);
  const loginWithPassword = useCallback(
    async (email: string, password: string) => {
      //reach out to the server for a login
      const response = await fetch(`${baseUrl}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.status === 200) {
        const { authToken } = await response.json();
        setToken(authToken);
        setIsAuthenticated(true);
        localStorage.setItem("xano-token", authToken);
      } else {
        const body = await response.text();
        toast.error("Could not log in with these credentials");
        throw new Error(body);
      }
    },
    []
  );

  const signupWithPassword = useCallback(
    async (email: string, password: string) => {
      //reach out to the server for a login
      const response = await fetch(`${baseUrl}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.status === 200) {
        const { authToken } = await response.json();
        setToken(authToken);
        setIsAuthenticated(true);
        localStorage.setItem("xano-token", authToken);
      } else {
        const body = await response.text();
        throw new Error(body);
      }
    },
    []
  );
  const navigate = useNavigate();
  const loginWithCircle = useCallback(async () => {
    //Make sure the wallet is connected
    //get the query parameters
    const params = new URLSearchParams(window.location.search);
    const key = params.get("token");
    if (!key) return;
    // const decodedKey = key && atob(key);
    // if (!decodedKey) throw new Error("No key found in query parameters");
    // const decodedObj = JSON.parse(decodedKey);
    // const { circleId, email } = decodedObj as {
    //   circleId: string;
    //   email: string;

    // };

    // const obj = { circleId, email };
    const response = await fetch(`${baseUrl}/auth/circle-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: key, // window.btoa(JSON.stringify(obj)),
      }),
    });
    if (response.status === 200) {
      const { authToken } = await response.json();
      setToken(authToken);
      setIsAuthenticated(true);
      localStorage.setItem("xano-token", authToken);
      params.delete("token");
      navigate(window.location.pathname);
    } else {
      const body = await response.text();
      throw new Error(body);
    }
  }, [navigate]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const refresh = useCallback(async () => {
    const response = await fetch(`${baseUrl}/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      const { authToken } = json;
      setToken(authToken);
    } else {
      logout();
    }
  }, [logout, token]);

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key === "xano-token") {
        const token = localStorage.getItem("xano-token");
        if (token) {
          setToken(token);
          setIsAuthenticated(true);
        } else {
          setToken("");
          setIsAuthenticated(false);
        }
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, []);

  const value = useMemo(() => {
    return {
      isAuthenticated,
      token,
      logout,
      loginWithPassword,
      loginWithCircle,
      signupWithPassword,
      refresh,
    };
  }, [
    isAuthenticated,
    token,
    logout,
    loginWithPassword,
    loginWithCircle,
    signupWithPassword,
    refresh,
  ]);
  if (isAuthenticated) {
    return <Provider value={value}>{children}</Provider>;
  } else {
    return <Provider value={value}>{fallback}</Provider>;
  }
};
export const useAuthentication = () => {
  const contextValue = useContext(context);
  return contextValue;
};
const EMPTYOBJECT = {};
export const useAuthenticatedFetch = () => {
  const { token, logout } = useAuthentication();
  return useCallback(
    async (path: string, options: RequestInit = EMPTYOBJECT) => {
      const url = baseUrl + path;
      const response = await fetch(url, {
        ...options,
        headers: {
          ...(options.headers || {}),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401) {
        logout();
      }
      return response;
    },
    [token, logout]
  );
};

export const useAuthenticatedQuery = <T,>(
  path: string,
  options?: RequestInit,
  ignore: boolean = false
) => {
  const fetch = useAuthenticatedFetch();
  const doIgnore = !!ignore;
  return useData(
    useCallback(async () => {
      if (doIgnore) return undefined;
      console.log("Running useData for ", path, new Date().toISOString());
      const data = await fetch(path, options);
      const json = await data.json();
      return json as T;
    }, [path, options, fetch, doIgnore]),
    [path, options, doIgnore]
  );
  // const [data, setData] = useState<T | undefined>();
  // const [error, setError] = useState<Error>();
  // const [loading, setLoading] = useState(true);
  // const optionsRef = useRef(options);
  // optionsRef.current = options;
  // const refresh = useCallback(async () => {
  //   // const fetchData = async () => {
  //   try {
  //     const response = await fetch(path, optionsRef.current);
  //     const data: T = await response.json();
  //     setData(data);
  //   } catch (e) {
  //     setError(e as Error);
  //   } finally {
  //     setLoading(false);
  //   }
  //   // };
  //   // fetchData();
  // }, [fetch, path]);
  // useEffect(() => {
  //   refresh();
  // }, [refresh]);
  // return { data, error, loading, refresh };
};
export type User = {
  name: string;
  email: string;
  circleId: string;
  avatar_url: string;
};
export const useMe = () => {
  const fetch = useAuthenticatedFetch();
  const { data, isLoading, refetch } = useAuthenticatedQuery<User>(`/auth/me`);
  const update = useCallback(
    async (options: { name: string; password?: string }) => {
      const response = await fetch("/auth/me", {
        method: "POST",
        body: JSON.stringify({
          name: options.name,
          password: options.password || "",
        }),
      });
      if (response.status === 200) {
        refetch();
        const json = (await response.json()) as User;
        return json;
      } else throw new Error(response.statusText);
    },
    [fetch, refetch]
  );

  return useMemo(
    () => ({ data, isLoading, refetch, update }),
    [data, isLoading, refetch, update]
  );
};
export const useRefreshToken = () => {
  const { refresh } = useAuthentication();
  useEffect(() => {
    const interval = setInterval(() => {
      refresh();
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, [refresh]);
};

export default Authenticator;

import { PlusIcon } from "@heroicons/react/24/outline";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedFetch } from "./Authenticator";
import { EZLLambda } from "./EmbedProviderBase";
import { MPOption, useModalPicker } from "./ModalPicker";

const templates: (MPOption & { body: string; ezl_type_id: number })[] = [
  {
    key: "js-300",
    title: "15-minute JavaScript",
    body: "//let's gooooo",
    subtitle: "Just a simple JavaScript starter template",
    ezl_type_id: 2,
  },
  {
    key: "js-3600",
    title: "1-hour Javascript",
    subtitle: "Just a simple Javascript starter template",
    body: `//let's gooooo`,
    ezl_type_id: 1,
  },
];

const AddEmbed: FC<{ projectId: number; onAdd?: () => void }> = ({
  onAdd = () => {},
  projectId,
}) => {
  const pick = useModalPicker();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const add = useCallback(async () => {
    const result = await pick({
      title: "Create a new Script",
      message: "Select a template for your new script",
      options: templates,
    });
    const template = templates.find((t) => t.key === result);
    if (template) {
      const response = await fetch("/me/lambda", {
        method: "POST",
        body: JSON.stringify({
          name:
            "Lambda created " + new Date().toLocaleString().replace(/\//g, "-"),
          type: template.ezl_type_id,
          body: template.body,
          project_id: projectId,
        } as Partial<EZLLambda>),
      });
      const data: EZLLambda = await response.json();
      if (onAdd) onAdd();
      navigate("/editor/" + data.id);
    }
  }, [fetch, onAdd, navigate, pick, projectId]);

  return (
    <button
      className=" w-full bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
      onClick={add}
    >
      <PlusIcon className="h-5 w-5 inline-block mr-2" />
      {projectId
        ? "Create a new Script File"
        : "Create a new Script File Without a Project"}
    </button>
  );
};
export default AddEmbed;

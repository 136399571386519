import { useState, useCallback, useEffect } from "react";

export const useData = <T>(func: () => Promise<T>, dependencyArray: any[]) => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const getData = useCallback(() => {
    (async () => {
      setLoading(true);
      try {
        const newData = await func();
        setData(newData);
      } catch (e) {
        setData(undefined);
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func, ...dependencyArray]);

  useEffect(() => {
    getData();
  }, [getData]);

  const update = useCallback(
    (newData: T) => {
      setData(newData);
    },
    [setData]
  );

  return { data, isLoading: loading, error, refetch: getData, update };
};

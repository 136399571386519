import {
  useMemo,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { EZLProject } from "./Projects";
import { EZLType } from "./LanguageSwitch";
const BASEURL = "https://xw8v-tcfi-85ay.n7.xano.io/api:easylambda/";
export type EZLLambda = {
  id: number;
  body: string;
  guid: string;
  name: string;
  description: string;
  is_shared: boolean;
  project_id: number;
  _ezl_project?: EZLProject;
  ezl_type_id: number;
  _ezl_type?: EZLType;
};
const context = createContext({
  body: "",

  error: undefined,
  isLoading: false,

  refetch: () => {},
  save: async () => {},
  saving: false,
  update: (newBody) => {},
  setBody: (newBody: string) => {},
  data: undefined,
  unsaved: false,
  name: "",
  setName: (newName: string) => {},
  isShared: false,
  setIsShared: (newIsShared: boolean) => {},
} as {
  body: string;

  error: Error | undefined;
  isLoading: boolean;

  refetch: () => void;
  save: () => Promise<void>;
  saving: boolean;
  update: (newData: EZLLambda) => void;
  setBody: (newBody: string) => void;
  data?: EZLLambda;
  unsaved: boolean;
  name: string;
  setName: (newName: string) => void;
  isShared: boolean;
  setIsShared: (newIsShared: boolean) => void;
  loaded: boolean;
  setLoaded: (newLoaded: boolean) => void;
});
const { Provider } = context;

export { context, Provider };

export const useLambda = () => {
  return useContext(context);
};

export const useRunCurl = () => {
  const { data } = useLambda();
  const devEmbedUrl = useMemo(() => {
    return `curl -X POST ${BASEURL}run/${data?.guid} 
    -H "Content-Type: application/json" 
    -H "Authorization: Bearer ${data?._ezl_project?.tokens[0]}"`;
  }, [data]);
  return devEmbedUrl;
};
export const useRunFetch = () => {
  const { data } = useLambda();
  const devEmbedUrl = useMemo(() => {
    return `
const response = await fetch("${BASEURL}run/${data?.guid}", { 
  headers: { 
    "Content-Type": "application/json",
    "Authorization": "Bearer ${data?._ezl_project?.tokens[0]}"
  }
});
const data = await response.json();
    `;
  }, [data]);
  return devEmbedUrl;
};

export const useRunUrl = () => {
  const { data } = useLambda();
  const devEmbedUrl = useMemo(() => {
    return `${BASEURL}run/${data?.guid}`;
  }, [data]);
  return devEmbedUrl;
};
export const useShare = () => {
  const { data, save, setIsShared, isShared } = useLambda();
  const share = useCallback(async () => {
    if (!data) return;
    if (!isShared) {
      setIsShared(true);
    }
  }, [data, setIsShared, isShared]);
  const saveRef = useRef(save);
  saveRef.current = save;
  useEffect(() => {
    saveRef.current();
  }, [isShared]);
  return share;
};
export const useShareLink = () => {
  const { data, isShared } = useLambda();
  const shareLink = useMemo(() => {
    if (!data || !isShared) return;
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/shared/" +
      data.guid
    );
  }, [data, isShared]);
  return shareLink;
};

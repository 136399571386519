import { FC, Fragment, ReactNode } from "react";
import { createPortal } from "react-dom";

const SidebarPortal: FC<{ children: ReactNode }> = ({ children }) => {
  const collection = document.getElementsByClassName("sidebar-portal");
  const sidebars = [];
  if (collection.length > 0) {
    for (let i = 0; i < collection.length; i++) {
      const el = collection[i];
      if (el) {
        sidebars.push(createPortal(children, el));
      }
    }
  }
  return <Fragment>{sidebars}</Fragment>;
};
export default SidebarPortal;

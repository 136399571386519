import { ReactNode, createContext, useContext } from "react";
export const context = createContext({
  title: "",
  setTitle: (newTitle: ReactNode) => {},
  pageKey: "",
  setPageKey: (newPageKey: string) => {},
} as { title: ReactNode; setTitle: (newTitle: ReactNode) => void; pageKey: string; setPageKey: (newPageKey: string) => void });
export const Provider = context.Provider;
export const useHome = () => {
  return useContext(context);
};

import {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { SHA256 } from "crypto-js";
import { useData } from "./useData";
import { EZLLambda, Provider } from "./EmbedProviderBase";
import { useAuthenticatedFetch } from "./Authenticator";

const SharedEmbedProvider: FC<{ id: number; children: ReactNode }> = ({
  children,
  id,
}) => {
  const [body, setBody] = useState("");
  const bodyHash = useMemo(() => {
    return SHA256(body).toString();
  }, [body]);
  const [savedBody, setSavedBody] = useState(SHA256("").toString());
  const [loaded, setLoaded] = useState(false);
  const fetch = useAuthenticatedFetch();
  const { data, isLoading, error, update, refetch } = useData(
    useCallback(async () => {
      if (!id) return undefined;
      const response = await fetch(`/me/lambda/${id}`);
      const data = (await response.json()) as EZLLambda;
      return data;
    }, [id, fetch]),
    [id, fetch]
  );
  const bodyHashRef = useRef(bodyHash);
  bodyHashRef.current = bodyHash;
  const savedBodyRef = useRef(savedBody);
  savedBodyRef.current = savedBody;
  useEffect(() => {
    if (data) {
      if (!loaded) {
        setBody(data.body);
        setLoaded(true);
        setSavedBody(SHA256(data.body).toString());
        setName(data.name);
        // console.log("A I set name to ", data.name);
        setIsShared(data.is_shared);
      } else if (
        SHA256(data.body).toString() !== bodyHashRef.current &&
        bodyHashRef.current === savedBodyRef.current
      ) {
        setBody(data.body);
        setSavedBody(SHA256(data.body).toString());
        setName(data.name);
        // console.log("B I set name to ", data.name);
        setIsShared(data.is_shared);
      } else {
        // setType(data.type);
        // setName(data.name);
        // console.log("C I set name to ", data.name);
        // setIsShared(data.is_shared);
      }
    }
  }, [data, loaded]);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [saveAgain, setSaveAgain] = useState(false);
  const save = useCallback(async () => {
    if (!data?.id) return;
    if (saving) {
      setSaveAgain(true);
      return;
    }
    setSaving(true);
    setSavedBody(SHA256(body).toString());
    await fetch(`/me/lambda/${data?.id}`, {
      method: "POST",
      body: JSON.stringify({ body, name, is_shared: isShared }),
      headers: { "Content-Type": "application/json" },
    });
    setSaving(false);
  }, [saving, data, body, fetch, name, isShared]);
  useEffect(() => {
    if (saveAgain && !saving) {
      setSaveAgain(false);
      save();
    }
  }, [saveAgain, saving, save]);
  const value = useMemo(() => {
    return {
      body,
      setBody,
      isLoading,
      error,
      update,
      refetch,
      save,
      saving,
      data,
      unsaved: bodyHash !== savedBody,
      setName,
      name,
      isShared,
      setIsShared,
      loaded,
      setLoaded,
    };
  }, [
    body,
    error,
    isLoading,
    refetch,
    save,
    saving,
    update,
    data,
    bodyHash,
    savedBody,
    setName,
    name,
    isShared,
    setIsShared,
    loaded,
    setLoaded,
  ]);
  return <Provider value={value}>{children}</Provider>;
};
export default SharedEmbedProvider;

import {
  CloudArrowDownIcon,
  CodeBracketIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useAuthenticatedFetch, useAuthenticatedQuery } from "./Authenticator";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { FC, useCallback, useEffect } from "react";
import { useHome } from "./HomeBase";
import { EZLLambda } from "./EmbedProviderBase";
import EmbedList from "./EmbedList";
import { useProjectRemove } from "./useProjectRemove";
import AddEmbed from "./AddEmbed";
import SidebarPortal from "./SidebarPortal";
import saveAs from "file-saver";
import JSZip from "jszip";
export type EZLProject = {
  id: number;
  name: string;
  description: string;
  lambdas?: number;
  shared_with: {
    id: number;
    email: string;
  }[];
  user_id: number;
  tokens: string[];
};

const AddProject: FC<{ onAdd: () => void }> = ({ onAdd }) => {
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const add = useCallback(async () => {
    const response = await fetch("/me/project", {
      method: "POST",
      body: JSON.stringify({
        name: "New Project",
        description: "A new project",
      }),
    });
    if (response.ok) {
      toast.success("Added a project");
      const newProject = (await response.json()) as EZLProject;
      navigate(`/project/${newProject.id}`);
      onAdd();
    }
  }, [fetch, onAdd, navigate]);
  return (
    <button
      className=" w-full bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
      onClick={add}
    >
      <PlusIcon className="h-5 w-5 inline-block mr-2" />
      Create a new Project
    </button>
  );
};

const Projects: FC = () => {
  const remove = useProjectRemove();
  const { setTitle, setPageKey } = useHome();
  useEffect(() => {
    setTitle("My Projects");
    setPageKey("projectlist");
  }, [setTitle, setPageKey]);

  const { data, refetch } = useAuthenticatedQuery<{
    items: EZLProject[];
  }>("/me/project");
  const { data: others, refetch: refetchEmbed } = useAuthenticatedQuery<{
    lambdas: EZLLambda[];
    project: EZLProject;
  }>(`/me/project/0`);

  if (!data)
    return (
      <div className="rounded-md p-5 bg-white shadow-md text-xl text-gray-200">
        <div className="animated-pulse">Loading...</div>
      </div>
    );
  return (
    <div className="flex-col space-y-4 mb-6">
      <SidebarPortal>
        <div className="w-full mx-2">
          {!!data.items && !!data.items.length && (
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                const archive = new JSZip();
                if (others.lambdas)
                  others.lambdas.forEach((js) => {
                    archive.file(
                      js.name + (js.language === "javascript" ? ".js" : ".py"),
                      js.body
                    );
                  });

                archive.generateAsync({ type: "blob" }).then((content) => {
                  saveAs(content, "Unassigned Scripts.zip");
                });
              }}
            >
              <CloudArrowDownIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Download Unassigned Scripts
            </button>
          )}
        </div>
      </SidebarPortal>
      <div>
        {/* <h2 className="text-gray-200 text-2xl font-semibold mb-2">
          My Projects
        </h2> */}
        {!!data.items?.length && (
          <div className="overflow-hidden bg-white shadow sm:rounded-md w-full p-5">
            <ul className="divide-y divide-gray-200">
              {data.items
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((project) => (
                  <li key={project.id}>
                    <Link
                      to={`/project/${project.id}`}
                      className="block hover:bg-gray-50"
                    >
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {project.name}
                            </p>
                            {project.description
                              .split("\n")
                              .map((line, index) => (
                                <p
                                  className="text-xs text-gray-500"
                                  key={index}
                                >
                                  {line}
                                </p>
                              ))}
                          </div>
                          <div className="ml-2 flex flex-shrink-0">
                            {/* {embed.is_shared && (
                   <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                     Shared
                   </p>
                 )} */}
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                              #{project.id}
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                              <CodeBracketIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {project.lambdas || 0}{" "}
                              {project.lambdas === 1
                                ? "script file"
                                : "script files"}
                            </p>
                          </div>
                          <button
                            className="mt-2 flex items-center text-sm text-red-500 hover:text-red-700 sm:mt-0"
                            onClick={(e) => {
                              console.log("removing");
                              remove(project.id, () => {
                                refetch();
                                refetchEmbed();
                              });
                              e.preventDefault();
                              e.stopPropagation();
                              return false;
                            }}
                          >
                            <TrashIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0"
                              aria-hidden="true"
                            />
                            <p>Delete</p>
                          </button>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <AddProject onAdd={refetch} />
      {!!others?.lambdas && !!others.lambdas.length && (
        <div className="flex-col mt-8">
          <h2 className="text-gray-200 text-2xl font-semibold mb-2 mt-8">
            Javascript files without a Project
          </h2>
          <EmbedList
            items={others.lambdas}
            onRemove={() => {
              refetch();
              refetchEmbed();
            }}
          />
        </div>
      )}
      <AddEmbed projectId={0} onAdd={refetchEmbed} />
    </div>
  );
};
export default Projects;

import { Fragment, useCallback, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useAuthenticatedFetch, useAuthenticatedQuery } from "./Authenticator";
import { useLambda } from "./EmbedProviderBase";
import { toast } from "react-toastify";
import { languages } from "monaco-editor";

export type EZLType = {
  id: number;
  name: string;
  description: string;
  language: string;
  sync: boolean;
  cancellable: boolean;
};

// const languages = [
//   {
//     id: "javascript",
//     name: "JavaScript",
//     avatar:
//       "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/240px-JavaScript-logo.png",
//   },
//   {
//     id: "python",
//     name: "Python",
//     avatar:
//       "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg",
//   },
// ];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const getImage = (language: string) => {
  switch (language) {
    case "javascript":
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/240px-JavaScript-logo.png";
    case "python":
      return "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg";
    default:
      return "";
  }
};

export default function TypeSwitch() {
  const {
    data,
    isLoading,
    error,
    refetch: refetchType,
  } = useAuthenticatedQuery<EZLType[]>("/me/type");

  const fetch = useAuthenticatedFetch();
  const { data: lambda, refetch: refetchLambda } = useLambda();
  const [type, setType] = useState<number>(lambda?.ezl_type_id || 0);
  useEffect(() => {
    if (lambda) {
      setType(lambda.ezl_type_id);
    }
  }, [lambda]);
  const updateType = useCallback(
    async (newType: number) => {
      setType(newType);
      await fetch(`/me/lambda/${lambda?.id}`, {
        method: "POST",
        body: JSON.stringify({ type: newType }),
      });
      toast.success("Updated type");
      refetchLambda();
    },
    [refetchLambda, fetch, lambda]
  );
  if (isLoading) return <div>Loading...</div>;
  return (
    <Listbox value={type} onChange={updateType}>
      {({ open }) => {
        const selected =
          data.find((l) => l.id === lambda.ezl_type_id) || data[0];
        return (
          <>
            {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              Assigned to
            </Listbox.Label> */}
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <img
                    src={getImage(selected.language)}
                    alt=""
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                  <span className="ml-3 block truncate">{selected.name}</span>
                </span>

                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {data.map((l) => (
                    <Listbox.Option
                      key={l.id}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={l.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <img
                              src={getImage(l.language)}
                              alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                            />
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {l.name}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <span className="mt-1 block  text-xs">
                              {l.description}
                            </span>
                          </div>
                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        );
      }}
    </Listbox>
  );
}

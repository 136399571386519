import { FC, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedQuery } from "./Authenticator";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import SidebarPortal from "./SidebarPortal";
import { EZLLambda } from "./EmbedProviderBase";
import { useHome } from "./HomeBase";
export type EZLRequest = {
  id: number;
  created_at: number;
  status: string;
  type: string;
  ezl_lambda_id: number;
  completed_at: number;
  _ezl_lambda: EZLLambda;
};
const Requests: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useAuthenticatedQuery<EZLRequest[]>(
    `/me/runs/${id}`
  );
  const { setTitle } = useHome();
  const { data: lambda, isLoading: isLambdaLoading } =
    useAuthenticatedQuery<EZLLambda>("/me/lambda/" + id);
  useEffect(() => {
    setTitle(
      "Requests for " +
        (lambda ? (lambda?.name || "") + " (#" + id + ")" : "Lambda #" + id)
    );
  }, [lambda]);
  const intervalRef = useRef<any>(null);
  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    let interval = 30000;
    if (data) {
      if (data.some((r) => r.status === "running")) {
        interval = 3000;
      }
      intervalRef.current = setInterval(() => {
        refetch();
      }, interval);
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [refetch, data]);
  const Sidebar = () => (
    <SidebarPortal>
      <div className="text-white flex-col m-2 space-y-4">
        <div className="w-full mx-2">
          <button
            className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
            onClick={() => navigate(`/editor/${id}`)}
          >
            <CodeBracketIcon
              className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            Back to Editor
          </button>
        </div>
      </div>
    </SidebarPortal>
  );
  if (isLoading && !data)
    return (
      <div>
        <Sidebar />
        <div className="bg-white border-gray-400 border-2 rounded-md flex justify-center p-5">
          <div className="animate-pulse">Loading...</div>
        </div>
      </div>
    );
  if (data)
    return (
      <div className="rounded-md bg-gray-100 p-2">
        <Sidebar />
        <ul role="list" className="divide-y divide-gray-300">
          {data.map((request) => (
            <li
              onClick={() => navigate(`/logs/${request.id}`)}
              key={request.id}
              className="flex justify-between gap-x-6 py-5 p-5 block hover:bg-blue-50 cursor-pointer"
            >
              <div className="flex gap-x-4">
                {/* <img
                className="h-12 w-12 flex-none rounded-full bg-gray-50"
                src={person.imageUrl}
                alt=""
              /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {new Date(request.created_at).toLocaleString()} (#
                    {request.id})
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {request.type}
                  </p>
                </div>
              </div>
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-800">
                  {request.completed_at
                    ? (
                        (request.completed_at - request.created_at) /
                        1000
                      ).toPrecision(4) + "s"
                    : "Pending  " +
                      ((Date.now() - request.created_at) / 1000).toPrecision(
                        4
                      ) +
                      "s"}
                </p>
                <p className="text-sm leading-6 text-gray-900">
                  {request.status}
                </p>
                {/* {person.lastSeen ? (
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  Last seen{" "}
                  <time dateTime={person.lastSeenDateTime}>
                    {person.lastSeen}
                  </time>
                </p>
              ) : (
                <div className="mt-1 flex items-center gap-x-1.5">
                  <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                  </div>
                  <p className="text-xs leading-5 text-gray-500">Online</p>
                </div>
              )} */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );

  return <Sidebar />;
};

export default Requests;

import Editor from "./Editor";
import { useNavigate, useParams } from "react-router-dom";
import EmbedProvider from "./EditEmbedProvider";
import { useHome } from "./HomeBase";
import { useEffect } from "react";

const EditEmbed = () => {
  const { id } = useParams();
  const { setPageKey } = useHome();
  useEffect(() => {
    setPageKey("editembed");
  }, [setPageKey]);
  const navigate = useNavigate();
  if (!id) return null;
  if (isNaN(parseInt(id))) navigate("/");
  return (
    <EmbedProvider id={parseInt(id)}>
      <Editor />
    </EmbedProvider>
  );
};

export default EditEmbed;

import { useCallback } from "react";
import { useAlert } from "./Alert";
import { useAuthenticatedFetch } from "./Authenticator";
import { toast } from "react-toastify";

export const useRemove = () => {
  const fetch = useAuthenticatedFetch();
  const { confirm } = useAlert();
  const remove = useCallback(
    async (id: number, callback?: () => void) => {
      const result = await confirm({
        title: "Delete this embed?",
        message: "This action cannot be undone.",
      });
      if (result === "accept") {
        await fetch(`/me/lambda/${id}`, {
          method: "DELETE",
        });
        toast.success("Deleted file");
        if (callback) callback();
      }
    },
    [fetch, confirm]
  );
  return remove;
};

import {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import MonacoEditor, { useMonaco } from "@monaco-editor/react";
import clipboardCopy from "clipboard-copy";
import {
  EZLLambda,
  useLambda,
  useRunFetch,
  useShare,
  useShareLink,
  useRunCurl,
  useRunUrl,
} from "./EmbedProviderBase";
import LanguageSwitch from "./LanguageSwitch";
import { useHome } from "./HomeBase";
import SidebarPortal from "./SidebarPortal";
import { toast } from "react-toastify";
import { SHA256 } from "crypto-js";
import { useDebounce } from "./useDebounce";
import {
  ClockIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CodeBracketIcon,
  CodeBracketSquareIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  FolderPlusIcon,
  HeartIcon,
  LinkIcon,
  LockClosedIcon,
  LockOpenIcon,
  PaperClipIcon,
  PlayCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  baseUrl,
  useAuthenticatedFetch,
  useAuthenticatedQuery,
} from "./Authenticator";
import { Link, useNavigate } from "react-router-dom";
import { useRemove } from "./useRemove";
import { format, check } from "prettier";
import prettierBabel from "prettier/parser-babel";
import { useModalPicker } from "./ModalPicker";
import { EZLProject } from "./Projects";
import { useMoveScript } from "./useMoveScript";
import { saveAs } from "file-saver";
import { Uri } from "monaco-editor";
import { useAlert } from "./Alert";
const Editor: FC = () => {
  const {
    body,
    data,
    isLoading,
    save,
    saving,
    setBody,
    unsaved,
    name,
    setName,
    setIsShared,
    isShared,
    setLoaded,
    refetch,
  } = useLambda();
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const projectData = data as unknown as
    | { _ezl_project: EZLProject }
    | undefined;
  const project = projectData?._ezl_project;
  //@TODO RHD
  // const monaco = useMonaco()
  // monaco?.getEditor().getPosition()
  useEffect(() => {
    if (!rootRef.current || !rootRef.current.parentElement?.parentElement)
      return;
    const ho = new ResizeObserver((entries) => {
      setHeight(entries[0].contentRect.height);
    });
    ho.observe(rootRef.current.parentElement);
    const wo = new ResizeObserver((entries) => {
      // console.log("Width detected", entries[0]);
      const newWidth = entries[0].contentRect.width; // - entries[0].contentRect.left;
      // console.log("I will set width to ", newWidth);
      setWidth(newWidth);
    });
    wo.observe(rootRef.current.parentElement.parentElement);
    return () => {
      ho.disconnect();
      wo.disconnect();
    };
  }, [data]);
  useEffect(() => {
    const windowWidth = window.innerWidth;
    const leftPos = rootRef.current?.getBoundingClientRect().left;
    if (typeof leftPos !== "undefined") {
      if (width > windowWidth - leftPos - 20) {
        setWidth(windowWidth - leftPos - 20);
      }
    }
  }, [width]);
  const [showCompiled, setShowCompiled] = useState(false);
  const { data: compiledData, refetch: refetchCompiled } =
    useAuthenticatedQuery<{
      compiled: string;
    }>(`/me/compiled/${data?.id}`, undefined, !data?.id);
  const refetchRef = useRef(refetchCompiled);
  refetchRef.current = refetchCompiled;
  useEffect(() => {
    if (showCompiled) refetchRef.current();
  }, [showCompiled]);
  const { setTitle } = useHome();
  useEffect(() => {
    if (name)
      setTitle(
        name + (data._ezl_type.language === "javascript" ? ".js" : ".py")
      );
    else if (isLoading) setTitle("");
    else setTitle("Untitled Document");
  }, [name, setTitle, unsaved, data, isLoading]);

  const debouncedSave = useDebounce(
    SHA256(body + data?._ezl_type.language).toString(),
    1000
  );
  const saveRef = useRef(save);
  saveRef.current = save;
  const haveSavedRef = useRef(false);
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  useEffect(() => {
    if (!isLoadingRef.current) {
      if (!haveSavedRef.current) {
        console.log("I will not save because I have not saved yet");
        haveSavedRef.current = true;
        return;
      }
      console.log("I decided to save", debouncedSave);
      saveRef.current();
    }
  }, [debouncedSave]);
  const share = useShare();
  const sharedUrl = useShareLink();
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  const remove = useRemove();
  const moveScript = useMoveScript();
  const checked = useMemo(() => {
    try {
      return (
        data?._ezl_type.language === "javascript" &&
        (check(body.replaceAll("\r\n", "\n"), {
          parser: "babel",
          plugins: [prettierBabel],
        }) ||
          check(body.replaceAll("\r\n", "\n") + "\n", {
            parser: "babel",
            plugins: [prettierBabel],
          }))
      );
    } catch (e) {
      return false;
    }
  }, [body, data]);
  const pick = useModalPicker();
  const monaco = useMonaco();
  useEffect(() => {
    if (monaco) {
      monaco.editor
        .getModel(new Uri())
        ?.setEOL(monaco.editor.EndOfLineSequence.LF);
    }
  }, [monaco]);
  const curl = useRunCurl();
  const url = useRunUrl();
  const fetchString = useRunFetch();
  const { alert } = useAlert();
  const [isTesting, setIsTesting] = useState(false);
  const runTest = useCallback(async () => {
    if (isTesting) {
      toast.error("Already testing");
      return;
    }
    const token = data._ezl_project?.tokens[0];
    if (!token) {
      toast.error("No valid token for this user");
      return;
    }
    toast.info("Starting test run...");
    setIsTesting(true);
    const response = await window.fetch(`${baseUrl}/test/${data.guid}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const json = await response.json();

    console.log("Response from test", json);
    toast.success("Test run complete");
    setIsTesting(false);
    //redirect to request id
    const requestId = json.run_id;
    console.log("Request ID is", requestId);
    if (requestId) {
      navigate(`/logs/${requestId}`);
    } else {
      await alert({
        title: "Response from test run",
        message: <pre>{JSON.stringify(json, null, 2)}</pre>,
      });
    }
  }, [fetch, url, alert, isTesting, navigate]);
  if (!data) return null;
  // if (!width) return null;
  return (
    <div
      className="relative w-full"
      ref={rootRef}
      style={{
        height: height - 25,
        width: width,
        left: 0,
      }}
    >
      <SidebarPortal>
        <div className="text-white flex-col m-2 space-y-4">
          <div className="w-full mx-2">
            <div className=" text-xs text-gray-400">Nickname of document</div>
            <input
              className="bg-black p-2 rounded-md text-white w-full"
              value={name}
              onChange={(e) => {
                //   update({ ...data, name: e.target.value });

                setName(e.target.value);
                save();
              }}
            />
          </div>
          <div className="w-full mx-2">
            <div className=" text-xs text-gray-400">Type of Embed</div>
            <LanguageSwitch />
          </div>
          <div className="w-full mx-2">
            <Link
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              to={`/requests/${data.id}`}
            >
              <ClockIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Request History
            </Link>
            {body.includes("@scminclude") && (
              <button
                className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                onClick={() => {
                  setShowCompiled((o) => !o);
                }}
              >
                <CodeBracketSquareIcon
                  className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  aria-hidden="true"
                />
                {showCompiled ? "Show Editor" : "Show Compiled Preview"}
              </button>
            )}

            {data?._ezl_type.language === "javascript" && (
              <button
                className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                onClick={() => {
                  try {
                    const newBody = format(body.replaceAll("\r\n", "\n"), {
                      parser: "babel",
                      plugins: [prettierBabel],
                    });
                    setBody(newBody);
                    if (
                      check(newBody, {
                        parser: "babel",
                        plugins: [prettierBabel],
                      })
                    ) {
                      toast.success("Prettified code!");
                    } else {
                      toast.error("Prettified code, but it's still invalid");
                    }
                  } catch (e) {
                    console.error(e);
                    toast.error("Failed to prettify: " + (e as Error).message);
                  }
                }}
              >
                <HeartIcon
                  className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  aria-hidden="true"
                />
                Prettify code
              </button>
            )}

            <button
              disabled={isTesting}
              className={
                isTesting
                  ? "w-full text-gray-500  group flex items-center rounded-md px-2 py-2 text-sm font-medium animate-pulse"
                  : "w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              }
              onClick={() => {
                if (!isTesting) runTest();
              }}
            >
              <PlayCircleIcon
                className={
                  isTesting
                    ? "text-gray-500  mr-3 h-6 w-6 flex-shrink-0 animate-pulse"
                    : "text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                }
                aria-hidden="true"
              />
              Test
            </button>

            {/* <textarea value={curl} className="bg-black text-xs w-full h-20" /> */}
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                clipboardCopy(curl);
                toast.success("Copied curl command to clipboard");
              }}
            >
              <CodeBracketIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Copy curl example
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                clipboardCopy(fetchString);
                toast.success("Copied fetch tag to clipboard");
              }}
            >
              <CodeBracketIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Copy fetch example
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                clipboardCopy(url);
                toast.success("Copied development tag to clipboard");
              }}
            >
              <CodeBracketIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Copy target url
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                const response = await fetch("/me/lambda", {
                  method: "POST",
                  body: JSON.stringify({
                    name: name + " (copy)",
                    type: data._ezl_type.id,
                    body,
                  }),
                });
                if (response.ok) {
                  const data: EZLLambda = await response.json();
                  toast.success("Copied " + name);
                  // console.log("Got back answer of ", data);
                  moveScript(data.id, () => {
                    setLoaded(false);
                    navigate(`/${data.id}`);
                    window.location.reload();
                  });
                } else {
                  toast.error("Failed to clone");
                }
              }}
            >
              <DocumentDuplicateIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Duplicate
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                remove(data.id, () => {
                  navigate("/");
                });
              }}
            >
              <TrashIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Delete
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                if (project?.id) navigate("/project/" + project?.id);
                else navigate("/");
              }}
            >
              <FolderIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              {project ? project.name + " (#" + project.id + ")" : "Unassigned"}
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                moveScript(data.id, refetch);
              }}
            >
              <FolderPlusIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Move to Another Project
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                saveRef.current();
              }}
            >
              <CloudArrowUpIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Save to Cloud
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                saveAs(
                  new Blob([body], { type: "plain/text" }),
                  name +
                    (data?._ezl_type.language === "javascript" ? ".js" : ".py")
                );
              }}
            >
              <CloudArrowDownIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Download file
            </button>
          </div>
        </div>
      </SidebarPortal>
      <div className="flex flex-row w-full justify-end space-x-4">
        {!showCompiled && (
          <Fragment>
            <div className="text-gray-200 px-2">
              <button
                onClick={() => {
                  try {
                    let newBody = body.replaceAll("\r\n", "\n");
                    if (data?._ezl_type.language === "javascript") {
                      newBody = format(newBody, {
                        parser: "babel",
                        plugins: [prettierBabel],
                      });
                      if (
                        check(newBody, {
                          parser: "babel",
                          plugins: [prettierBabel],
                        })
                      ) {
                        toast.success("Prettified code!");
                      } else {
                        toast.error("Failed to prettify code!");
                      }
                    } else {
                      toast.success("Cleaned newlines in python code");
                    }
                    setBody(newBody);
                  } catch (e) {
                    console.error(e);
                    toast.error("Failed to prettify: " + (e as Error).message);
                  }
                }}
              >
                {data?._ezl_type.language === "javascript" &&
                  (checked ? (
                    <span className="px-2 text-xs bg-green-800 rounded-full p-1">
                      Prettified!
                    </span>
                  ) : (
                    <span className="px-2 text-xs bg-red-800 rounded-full p-1">
                      Not Prettified
                    </span>
                  ))}
              </button>
            </div>
            <div className=" text-gray-200">
              <button onClick={save} disabled={!unsaved}>
                {unsaved ? (
                  <span className="text-xs px-2 bg-red-800 rounded-full p-1">
                    Not yet saved
                  </span>
                ) : saving ? (
                  <span className="text-xs px-2 bg-yellow-800 rounded-full p-1">
                    Saving...
                  </span>
                ) : (
                  <span className="text-xs px-2 bg-green-800 rounded-full p-1">
                    Saved on server
                  </span>
                )}
              </button>
            </div>
          </Fragment>
        )}
      </div>
      <MonacoEditor
        className="my-2"
        language={data?._ezl_type.language || "javascript"}
        theme="vs-dark"
        value={body}
        options={{ wordWrap: "on" }}
        onChange={(body) => {
          if (!isLoading) {
            setBody(typeof body !== "undefined" ? body : "");
          }
        }}
      />
    </div>
  );
};

export default Editor;

import { Routes, Route, Navigate } from "react-router-dom";
// import SharedEditEmbed from "./SharedEditEmbed";
import Projects from "./Projects";
import Project from "./Project";
import EditEmbed from "./EditEmbed";
import Requests from "./Requests";
import Logs from "./Logs";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Projects />} />
      <Route path="/project/:id" element={<Project />} />
      {/* <Route path="/shared/:guid" element={<SharedEditEmbed />} /> */}
      <Route path="/editor/:id" element={<EditEmbed />} />
      <Route path="/requests/:id" element={<Requests />} />
      <Route path="/logs/:id" element={<Logs />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default MainRoutes;

import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedQuery } from "./Authenticator";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { EZLRequest } from "./Requests";
import SidebarPortal from "./SidebarPortal";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ClockIcon,
  CodeBracketIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { useHome } from "./HomeBase";
export type EZLLog = {
  id: number;
  timestamp: number;
  data: any[];
  level: string;
};
const Logs: FC = () => {
  const { setTitle } = useHome();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    setTitle("Logs for Run #" + id);
  }, [setTitle, id]);
  const { data, isLoading, refetch } = useAuthenticatedQuery<{
    request: EZLRequest;
    logs: EZLLog[];
  }>(`/me/logs/${id}`);

  const intervalRef = useRef<any>(null);
  const [isPaused, setIsPaused] = useState(false);
  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (data?.request.status !== "running" || isPaused) return;
    intervalRef.current = setInterval(() => {
      refetch();
    }, 3000);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [refetch, data?.request.status, isPaused]);
  const [isReversed, setIsReversed] = useState(true);
  const logs = useMemo(() => {
    if (!data) return [];
    return isReversed ? [...data.logs].reverse() : data.logs;
  }, [data, isReversed]);
  const Sidebar = () => (
    <SidebarPortal>
      <div className="text-white flex-col m-2 space-y-4">
        <div className="w-full mx-2">
          <div className="border-gray-500 border-2 rounded-md p-2">
            <div className="w-full text-gray-300">
              Request #{id} started{" "}
              {new Date(data.request.created_at).toLocaleString()} is{" "}
              {data.request.status}{" "}
              {data.request.completed_at
                ? " in " +
                  (
                    (data.request.completed_at - data.request.created_at) /
                    1000
                  ).toPrecision(4) +
                  "s"
                : ((Date.now() - data.request.created_at) / 1000).toPrecision(
                    4
                  ) + "s"}
            </div>
          </div>
          {!isReversed && (
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => setIsReversed(true)}
            >
              <ArrowUpIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Set Newest Results First
            </button>
          )}
          {!data?.request.completed_at && isPaused && (
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => setIsPaused(false)}
            >
              <PlayIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Resume Refresh
            </button>
          )}
          {!data?.request.completed_at && !isPaused && (
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => setIsPaused(true)}
            >
              <PauseIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Pause Refresh
            </button>
          )}
          {isReversed && (
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => setIsReversed(false)}
            >
              <ArrowDownIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Set Newest Results Last
            </button>
          )}
          <button
            className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
            onClick={() => navigate(`/requests/${data.request?.ezl_lambda_id}`)}
          >
            <ClockIcon
              className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            Back to Requests
          </button>
          <button
            className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
            onClick={() => navigate(`/editor/${data.request?.ezl_lambda_id}`)}
          >
            <CodeBracketIcon
              className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            Back to Editor
          </button>
        </div>
      </div>
    </SidebarPortal>
  );
  if (!data) return <div>Loading...</div>;
  if (logs) {
    return (
      <div className="rounded-md bg-gray-100 p-2 w-screen">
        <Sidebar />

        <ul role="list" className="divide-y divide-gray-300">
          {logs.map((log) => (
            <li
              key={log.id}
              className="flex justify-between gap-x-6 py-2 p-5 block w-full "
            >
              <div className=" gap-x-2 w-full ">
                <div className="flex justify-start gap-x-2 ">
                  <p
                    className="text-xs"
                    title={new Date(log.timestamp).toLocaleString()}
                  >
                    t+
                    {((log.timestamp - data.request.created_at) / 1000).toFixed(
                      3
                    )}
                    s
                  </p>
                  <p className="text-xs bg-green-200 text-gray-500 p-1 px-2 rounded-full">
                    {log.level}
                  </p>

                  <div className="text-sm">
                    {log.data &&
                      log.data.map((d, i) =>
                        ["string", "number", "boolean", "null"].includes(
                          typeof d
                        ) ? (
                          <Fragment key={i}>
                            <span
                              className="cursor-pointer hover:underline break-words"
                              onClick={async () => {
                                await copy(d);
                                toast.success("Copied to clipboard!");
                              }}
                            >
                              {d}
                            </span>
                            {i !== log.data.length - 1 && (
                              <span className="text-gray-500">, </span>
                            )}
                          </Fragment>
                        ) : (
                          <span
                            onClick={async () => {
                              await copy(JSON.stringify(d));
                              toast.success("Copied to clipboard!");
                            }}
                            key={i}
                          >
                            {JSON.stringify(d, null, 2)}
                          </span>
                        )
                      )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return (
    <div>
      <Sidebar />
      No Logs Found
    </div>
  );
};
export default Logs;
